<template>
  <div>
    <Toast position="top-right" />

    <div class="card">
      <div class="card-body p-0 px-4">
        <div class="d-flex h-100 w-100 pr-5">
          <div class="d-flex align-items-center py-5 d-flex float-right col-8">
            <span class="p-input-icon-left col-6 mr-5">
              <i class="pi pi-search ml-5" />
              <InputText
                placeholder="Buscar por Título ou ID"
                type="text"
                class="w-100 ml-auto"
                v-model="filtros_chatbot.titulo"
                @input="filtrarFluxos"
              />
            </span>
            <span class="p-input-icon-left col-6 mr-5">
              <MultiSelect
                v-model="filtros_chatbot.categorias"
                :options="categorias"
                emptyFilterMessage="Nenhum resultado"
                :filter="true"
                placeholder="Filtrar por Categoria"
                class="w-100"
                @input="filtrarFluxos"
                optionLabel="text"
                optionValue="value"
              ></MultiSelect>
            </span>
          </div>
          <div
            class="d-flex py-5 d-flex float-right col-4"
            style="justify-content: flex-end"
          >
            <b-button
              v-if="isAdmin || hasPermission('gerenciar-campanha')"
              variant="success"
              class="b-button-custom float-right"
              @click="clickBotaoCadastro"
            >
              Novo Fluxo
            </b-button>
          </div>
        </div>

        <div class="aut-area-exibir">
          <IndexFluxo
            class="aut-camp-area"
            v-if="navSelecionada == 0"
            ref="IndexFluxos"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "../../../../core/services/api.service";
import MultiSelect from "primevue/multiselect";
import API_LINKS from "../automacao/api.links";
import InputText from "primevue/inputtext";

export default {
  components: {
    Toast: () => import("primevue/toast"),
    IndexFluxo: () => import("./Index.vue"),
    MultiSelect,
    InputText
  },
  data() {
    return {
      filtros_chatbot: {
        categorias: [],
        titulo: ""
      },
      categorias: [],
      categorias_chatbot: [],
      filtros_ativos: false,
      menusAtivos: true,
      menusChatbot: [],
      navSelecionada: 0,
      showOverlay: true,
      campanhas: [],
      labelBotao: ["Novo Fluxo"],
      aba: ["fluxo"]
    };
  },
  methods: {
    filtrarFluxos() {
      this.$refs.IndexFluxos.filtrarFluxos(this.filtros_chatbot);
    },
    mudarNav(nav) {
      this.navSelecionada = nav;
    },
    clickBotaoCadastro() {
      this.$router.push({
        path: "/painel/automacao/cadastro-fluxo"
      });
    },
    async getCategories() {
      const res = await ApiService.get(API_LINKS.chatbot_fluxo_categoria);
      this.categorias = res.data.categorias.map((categoria) => {
        return {
          ...categoria,
          text: categoria.fla_etiqueta,
          value: categoria.fluxo_categoria_id
        };
      });

      const curr_categories = JSON.parse(JSON.stringify(this.categorias));

      const visited_categories = {};
      for (let i = 0; i < curr_categories.length; i++) {
        const categoria = curr_categories[i];
        if (visited_categories[categoria.text]) {
          visited_categories[categoria.text] =
            visited_categories[categoria.text] + 1;
          curr_categories[i].text = `${categoria.text} (${
            visited_categories[categoria.text]
          })`;
        } else {
          visited_categories[categoria.text] = 1;
        }
      }

      this.categorias = curr_categories;

      return curr_categories;
    }
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Chatbot"
      },
      {
        title: "Fluxos",
        route: "/painel/chatbot"
      }
    ]);

    if (
      this.$route.params.toast != "undefined" &&
      this.$route.params.toast != null
    ) {
      setTimeout(() => {
        this.$toast.removeAllGroups();
        this.$toast.add(this.$route.params.toast);
      }, 2000);
    }
    await this.getCategories();
  },
  computed: {
    ...mapGetters(["isAdmin", "hasPermission"])
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300&display=swap");

.b-button-custom {
  width: 142px;
}

.aut-body {
  border-radius: 5px;
  background-color: #fff;
}

.aut-area-exibir {
  display: flex;
  height: 100%;
  width: 100%;
}

.aut-header {
  height: 70px;
  width: 100%;
}

.aut-camp-area {
  width: 100%;
  min-height: 500px;
}

.container-dashboard {
  border-radius: 15px;
  background-color: #fff;
  padding: 20px 10px 20px 10px;
  margin-bottom: 15px;
}

.campo-estatistica {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.dropdown-form {
  padding: 3px 0px;
  width: 170px;
  height: 45px;
}

.dropdown-form:hover {
  background-color: rgb(247, 247, 247);
}

.button-container {
  border: 1px solid #e4e6ef;
  outline: none;
  color: #e3e4ed;
  background-color: transparent;
  border-radius: 0.42rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 38px;
}
.button-container.btn i {
  padding: 0 !important;
}
</style>
